import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import { Home, SportsEsports } from '@material-ui/icons';

export const mainListItems = (
    <div>
        <Link to='/'>
            <ListItem button>
                <ListItemIcon>
                    <Home/>
                </ListItemIcon>
                <ListItemText primary='Home'/>
            </ListItem>
        </Link>
        <Link to='/gta'>
            <ListItem button>
                <ListItemIcon>
                    <SportsEsports/>
                </ListItemIcon>
                <ListItemText primary='GTA'/>
            </ListItem>
        </Link>
        <Link to='/phasmophobia'>
            <ListItem button>
                <ListItemIcon>
                    <SportsEsports/>
                </ListItemIcon>
                <ListItemText primary='Phasmophobia'/>
            </ListItem>
        </Link>
        <Link to='/frank'>
            <ListItem button>
                <ListItemIcon>
                    <SportsEsports/>
                </ListItemIcon>
                <ListItemText primary='Frank'/>
            </ListItem>
        </Link>
    </div>
);

export const secondaryListItems = (
    <div>
    </div>
);
