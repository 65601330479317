import React from 'react';
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import Dashboard from './components/dashboard';
import Index from './components';
import Gta from './components/gta';
import Phasmophobia from './components/phasmophobia';
import Page from './components/page';
import { Frank } from './components/frank';

const darkTheme = createMuiTheme({
    palette: {
        type: 'dark',
    },
});

function App(): JSX.Element {
    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <BrowserRouter>
                <Dashboard>
                    <Switch>
                        <Route exact path='/' render={() => <Page title='Games'><Index/></Page>}/>
                        <Route exact path='/gta' render={() => <Page title='GTA'><Gta/></Page>}/>
                        <Route exact path='/phasmophobia' render={() => <Page title='Phasmophobia'><Phasmophobia/></Page>}/>
                        <Route exact path='/frank' render={() => <Page title='Frank'><Frank/></Page>}/>
                    </Switch>
                </Dashboard>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
