import React, { useState } from 'react';
import { TextField } from '@material-ui/core';

export function Frank():JSX.Element {
    const [spawnChange, setSpawnChange] = useState(0.05);
    const [upperbound, setUpperbound] = useState(0.9);
    const base = 1 - (spawnChange >= 1 ? spawnChange / 100 : spawnChange);
    const toFind = 1 - (upperbound >= 1 ? upperbound / 100 : upperbound);

    const result = Math.log(toFind) / Math.log(base);
    return (
        <div>
            <TextField
                label='Spawn change'
                type='number'
                value={spawnChange}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const tmp = Number(event.target.value);
                    if (tmp > 0) {
                        setSpawnChange(tmp);
                    }
                }}
            />
            <br/>
            <TextField
                label='Upperbound'
                type='number'
                value={upperbound}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const tmp = Number(event.target.value);
                    if (tmp > 0) {
                        setUpperbound(tmp);
                    }
                }}
            />
            <br/>
            <p>Result: {result}</p>
        </div>
    );
}
