import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Switch, TextField } from '@material-ui/core';
import React from 'react';

interface Secondairy {
    name: string;
    value: number;
    weigth: number;
    divisible: boolean;
    amount: number;
}

interface Selected {
    name: string;
    amount: number;
}


export default function Gta():JSX.Element {
    const [targetValue, setTarget] = React.useState('tequila');
    const [cashValue, setCash] = React.useState(0);
    const [goldValue, setGold] = React.useState(0);
    const [cocaineValue, setCocaine] = React.useState(0);
    const [paintingValue, setPainting] = React.useState(0);
    const [weedValue, setWeed] = React.useState(0);
    const [playerValue, setPlayer] = React.useState(1);
    const [cutValue, setCut] = React.useState(50);
    const [hardValue, setHard] = React.useState(true);

    // weigth has 24 as denominator
    const data: Secondairy[] = [
        {
            name: 'Cash',
            value: 85000,
            weigth: 6,
            divisible: true,
            amount: cashValue,
        },
        {
            name: 'Cocaine',
            value: 220095,
            weigth: 12,
            divisible: true,
            amount: cocaineValue,
        },
        {
            name: 'Gold',
            value: 332184,
            weigth: 16,
            divisible: true,
            amount: goldValue,
        },
        {
            name: 'Painting',
            value: 189500,
            weigth: 12,
            divisible: false,
            amount: paintingValue,
        },
        {
            name: 'Weed',
            value: 147870,
            weigth: 9,
            divisible: true,
            amount: weedValue,
        },
    ].sort((a, b) => b.value / b.weigth - a.value / a.weigth);

    function calc(): Selected[] {
        const items: Selected[] = [];
        const max = playerValue * 24;
        for (const i of data) {
            const held = items.map(a => a.amount).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            if (held >= max) {
                return items;
            }
            if (i.amount > 0) {
                const total = i.weigth * i.amount;
                const remaining = max - held;
                let toAdd = total;
                if (total > remaining) {
                    if (i.divisible) {
                        toAdd = remaining;
                    }else {
                        for (let j = 0; j <= i.amount; j++) {
                            if (i.weigth * j <= remaining) {
                                toAdd = i.weigth * j;
                            }
                        }
                    }
                }

                items.push({
                    name: i.name,
                    amount: toAdd,
                });
            }
        }
        return items;
    }

    function total(items: Selected[]): number {
        let sum = items.map(i => {
            const item = data.find(item => item.name === i.name);
            if (!item)return 0;
            const amount = i.amount / item?.weigth * item.value;
            return amount;
        }).reduce((a, b) => a + b, 0);
        let target = 0;
        switch (targetValue) {
        case 'tequila':
            target = 900000;
            break;
        case 'rubyNecklace':
            target = 1000000;
            break;
        case 'bearerBonds':
            target = 1100000;
            break;
        case 'pinkDiamond':
            target = 1300000;
            break;
        default:
        }
        if (hardValue) {
            target *= 1.1;
        }
        sum += target;
        sum = sum / 100 * cutValue;
        return Math.round(sum);
    }

    const list = calc();

    return <div>
        <Grid container>
            <TextField
                id='standard-number'
                label='Players'
                type='number'
                value={playerValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = Number(event.target.value);
                    if (value < 1 || value > 4)return;
                    setPlayer(value);
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Grid>
        <Grid container>
            <TextField
                id='standard-number'
                label='Cut'
                type='number'
                value={cutValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = Number(event.target.value);
                    if (value < 0 || value > 100)return;
                    setCut(value);
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Grid>
        <Grid container>
            <FormControlLabel
                control={<Switch
                    checked={hardValue}
                    onChange={() => {
                        setHard(!hardValue);
                    }}
                    name='Hard mode'
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />}
                label='Hard mode'
            />
        </Grid>
        <Grid container>
            <FormControl component='fieldset'>
                <FormLabel component='legend'>Main objective</FormLabel>
                <RadioGroup aria-label='main objective' name='objective' value={targetValue} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const tmp = event.target as HTMLInputElement;
                    setTarget(tmp.value);
                }}>
                    <FormControlLabel value='tequila' control={<Radio />} label='Tequila' />
                    <FormControlLabel value='rubyNecklace' control={<Radio />} label='Ruby Necklace' />
                    <FormControlLabel value='bearerBonds' control={<Radio />} label='Bearer Bonds' />
                    <FormControlLabel value='pinkDiamond' control={<Radio />} label='Pink Diamond' />
                </RadioGroup>
            </FormControl>
        </Grid>
        <Grid container>
            <TextField
                id='standard-number'
                label='Cash'
                type='number'
                value={cashValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCash(Number(event.target.value));
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Grid>
        <Grid container>
            <TextField
                id='standard-number'
                label='Gold'
                type='number'
                value={goldValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setGold(Number(event.target.value));
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Grid>
        <Grid container>
            <TextField
                id='standard-number'
                label='Cocaine'
                type='number'
                value={cocaineValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCocaine(Number(event.target.value));
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Grid>
        <Grid container>
            <TextField
                id='standard-number'
                label='Painting'
                type='number'
                value={paintingValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setPainting(Number(event.target.value));
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Grid>
        <Grid container>
            <TextField
                id='standard-number'
                label='Weed'
                type='number'
                value={weedValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setWeed(Number(event.target.value));
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Grid>
        <Grid container>
            {
                list.map(i => {
                    const item = data.find(item => item.name === i.name);
                    if (!item)return;
                    const amount = i.amount / item?.weigth;
                    return <Grid container key={i.name}>{`${i.name} ${amount}`}</Grid>;
                })
            }
        </Grid>
        <Grid container>
            Total: {
                total(list)
            }
        </Grid>
    </div>;
}
