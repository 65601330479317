import { useEffect } from 'react';

// https://stackoverflow.com/a/54112771
function Page(props: {title:string; children: JSX.Element}): JSX.Element {
    useEffect(() => {
        document.title = props.title;
    }, [props.title]);
    return props.children;
}

export default Page;
