import React from 'react';
import {
    Container,
    FormLabel,
    Radio,
    Table,
    TableCell, TableHead,
    TableRow,
} from '@material-ui/core';

enum Evidence {
    FreezingTemperatures= 'Freezing Temperatures',
    EMFLevel5= 'EMF Level 5',
    GhostOrb= 'Ghost Orb',
    SpiritBox= 'Spirit Box',
    GhostWriting= 'Ghost Writing',
    Fingerprints= 'Fingerprints',
    DOTSProjector= 'D.O.T.S Projector',
}

const evidenceList = [Evidence.EMFLevel5, Evidence.GhostWriting, Evidence.Fingerprints, Evidence.SpiritBox, Evidence.FreezingTemperatures, Evidence.GhostOrb, Evidence.DOTSProjector];

interface Ghost {
    name: string;
    evidence: Evidence[];
}

const ghosts: Ghost[] = [
    {
        name: 'Banshee',
        evidence: [Evidence.GhostOrb, Evidence.Fingerprints, Evidence.DOTSProjector],
    },
    {
        name: 'Demon',
        evidence: [Evidence.FreezingTemperatures, Evidence.GhostWriting, Evidence.Fingerprints],
    },
    {
        name: 'Goryo',
        evidence: [Evidence.EMFLevel5, Evidence.Fingerprints, Evidence.DOTSProjector],
    },
    {
        name: 'Hantu',
        evidence: [Evidence.FreezingTemperatures, Evidence.GhostOrb, Evidence.Fingerprints],
    },
    {
        name: 'Jinn',
        evidence: [Evidence.FreezingTemperatures, Evidence.EMFLevel5, Evidence.Fingerprints],
    },
    {
        name: 'Mare',
        evidence: [Evidence.GhostOrb, Evidence.SpiritBox, Evidence.GhostWriting],
    },
    {
        name: 'Myling',
        evidence: [Evidence.EMFLevel5, Evidence.GhostWriting, Evidence.Fingerprints],
    },
    {
        name: 'Obake',
        evidence: [Evidence.EMFLevel5, Evidence.GhostOrb, Evidence.Fingerprints],
    },
    {
        name: 'Oni',
        evidence: [Evidence.FreezingTemperatures, Evidence.EMFLevel5, Evidence.DOTSProjector],
    },
    {
        name: 'Onryo',
        evidence: [Evidence.GhostOrb, Evidence.FreezingTemperatures, Evidence.SpiritBox],
    },
    {
        name: 'Phantom',
        evidence: [Evidence.SpiritBox, Evidence.Fingerprints, Evidence.DOTSProjector],
    },
    {
        name: 'Poltergeist',
        evidence: [Evidence.SpiritBox, Evidence.GhostWriting, Evidence.Fingerprints],
    },
    {
        name: 'Raiju',
        evidence: [Evidence.DOTSProjector, Evidence.EMFLevel5, Evidence.GhostOrb],
    },
    {
        name: 'Revenant',
        evidence: [Evidence.FreezingTemperatures, Evidence.GhostOrb, Evidence.GhostWriting],
    },
    {
        name: 'Shade',
        evidence: [Evidence.FreezingTemperatures, Evidence.EMFLevel5, Evidence.GhostWriting],
    },
    {
        name: 'Spirit',
        evidence: [Evidence.EMFLevel5, Evidence.SpiritBox, Evidence.GhostWriting],
    },
    {
        name: 'The Twins',
        evidence: [Evidence.EMFLevel5, Evidence.FreezingTemperatures, Evidence.SpiritBox],
    },
    {
        name: 'Wraith',
        evidence: [Evidence.EMFLevel5, Evidence.SpiritBox, Evidence.DOTSProjector],
    },
    {
        name: 'Yokai',
        evidence: [Evidence.GhostOrb, Evidence.SpiritBox, Evidence.DOTSProjector],
    },
    {
        name: 'Yurei',
        evidence: [Evidence.FreezingTemperatures, Evidence.GhostOrb, Evidence.DOTSProjector],
    },
];

interface EvidenceCollection {
    evidence: Evidence;
    value: string;
}

export default function Phasmophobia(): JSX.Element {
    const tmp = evidenceList.map(eve => ({ evidence: eve, value: 'unknown' }));
    const [value, setValue] = React.useState(tmp);

    function handleChange(evidence: Evidence, event: React.ChangeEvent<HTMLInputElement>): void {
        const cast = event.target as unknown as HTMLInputElement;
        const found = value.find(element => element.evidence === evidence);
        let newValue: EvidenceCollection[];
        if (found) {
            newValue = value.map(element => {
                if (element.evidence === evidence) {
                    return { evidence, value: cast.value };
                }else {
                    return element;
                }
            });
        }else {
            newValue = [...value];
            newValue.push({ evidence, value: cast.value });
        }
        setValue(newValue);
    }

    const evidenceSelectors = evidenceList.map(evidence => {
        const formValue = value.find(e => e.evidence === evidence)?.value;
        return (
            <React.Fragment key={evidence}>
                <TableRow>
                    <TableCell>{evidence}</TableCell>
                    <TableCell><Radio value='evidence' checked={formValue === 'evidence'}
                        onChange={(e): void => handleChange(evidence, e)}/></TableCell>
                    <TableCell><Radio value='unknown' checked={formValue === 'unknown'}
                        onChange={(e): void => handleChange(evidence, e)}/></TableCell>
                    <TableCell><Radio value='no-evidence' checked={formValue === 'no-evidence'}
                        onChange={(e): void => handleChange(evidence, e)}/></TableCell>
                </TableRow>
            </React.Fragment>
        );
    });

    const mustHave = value.filter(v => v.value === 'evidence').map(tmp => tmp.evidence);
    const dontHave = value.filter(v => v.value === 'no-evidence').map(tmp => tmp.evidence);
    const options = ghosts.filter(ghost => {
        for (let i = 0; i < mustHave.length; ++i) {
            if (!ghost.evidence.find(e => e === mustHave[i])) {
                return false;
            }
        }
        for (let i = 0; i < dontHave.length; ++i) {
            if (ghost.evidence.find(e => e === dontHave[i])) {
                return false;
            }
        }
        return true;
    });
    return (
        <div style={{ width: '100%', marginTop: 10 }}>
            <Container maxWidth='xs'>
                <FormLabel>Evidence</FormLabel>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>✓</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>?</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>x</TableCell>
                        </TableRow>
                        {evidenceSelectors}
                    </TableHead>
                </Table><br/>
                <FormLabel>Possibilities</FormLabel>
                <Table>
                    <TableHead>
                        {options.map(o => <TableRow key={o.name}>
                            <TableCell>{o.name}</TableCell>
                            {
                                o.evidence.filter(ev => !mustHave.find(mh => mh === ev))
                                    .map(v => <TableCell key={v}>{v}</TableCell>)
                            }
                        </TableRow>)
                        }
                    </TableHead>
                </Table>
            </Container>
        </div>
    );
}
